<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('imprint') }}</span>
    <div :class="`d-flex flex-column ${isWide ? 'mx-30 my-10 font-25' : isMobile ? 'font-15' : 'm-10 font-20'}`">
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Angaben gemäß § 5 TMG</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Geschäftsführerin:</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Buket Burc</span>
      <span :class="isMobile ? 'mt-5' : 'mt-10'">Firmenanschrift:</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Cremon 11</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">20457 Hamburg</span>
      <span :class="isMobile ? 'mt-5' : 'mt-10'">Steuernummer: 151/125/90053</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">UST ID: DE361515286</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Kontakt: <a href="mailto:info@eventboxde.com">info@eventboxde.com</a></span>
      <span :class="isMobile ? 'mt-5' : 'mt-10'">EU-Streitschlichtung</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)</span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">bereit: <a href="https://lec.europa.eu/consumers/odr" target="_blank">https://lec.europa.eu/consumers/odr</a></span>
      <span :class="isMobile ? 'mt-2' : 'mt-5'">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</span>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  }
};
</script>